import React, {useEffect} from "react";
import * as Yup from "yup";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import {Field, Form, Formik} from "formik";
import {Container, Grid} from "@mui/material";
import "../../CSS/resetPassword.css";
import {Link} from "react-router-dom";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Icon} from "@iconify/react";
// import Navbar from "./Navbar";
import {useState} from "react";
function ResetPassword() {
  // const history = useHistory();
  const {id, token} = useParams();
  console.log(id, token, "id-token");
  const [resetPassowrdRes, setResetPassowrdRes] = useState("");
  const [resetPassowrdResErr, setResetPassowrdResErr] = useState("");
  const [invitopen, setinvitopen] = React.useState(false);

  const handleinvitClose = () => {
    setinvitopen(false);
  };

  // useEffect(() => {
  //   document.title =  "Desi Travel Companion-reset password";
  // }, []);

  // const params = useParams();
  const ContactSchema = Yup.object().shape({
    new_password: Yup.string("Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirm_password: Yup.string(" Re Enter your password")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleResetLink = (values, {resetForm}) => {
    console.log(values, "reset");

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .post(` ${domain}/password-reset/${id}/${token}/`, {
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      })
      .then(response => {
        // alert(response.data.message);
        console.log(response, "res of reset password");
        if (response.data.message) {
          setResetPassowrdRes(response.data.message);
          setResetPassowrdResErr("");
          setinvitopen(true);
          resetForm();
        }
      })
      .catch(err => {
        // if (err.response) {
        //   alert(err.response.data.message);
        // } else alert(err.response.data.message);
        console.log(err, "catch error reset passowrd");
        if (err.response.data.message) {
          setResetPassowrdResErr(err.response.data.message);
          setResetPassowrdRes("");
          setinvitopen(true);
        }
      });
  };

  return (
    <div className="scrollBars">
      <div className="imageContainer">
        <img
          src="../../Assets/Images/desi-travel-campanion-logo.png"
          alt="first"
          className="resetImage"
        />
      </div>

      <div className="bodyBackground">
        <hr className="resetHr" />
        <Container>
          <div className="reset-innerContainer">
            <Link to="/login" style={{textDecoration: "none", color: "black"}}>
              <div className="iconLeftAlignment">
                <Icon icon="ion:arrow-back-sharp" />
                <span className="backArrow">Back to Login</span>
              </div>
            </Link>

            <div className="resetPasswordMain">
              <p className="resetPasswordHeading">Reset Password</p>
              <Formik
                initialValues={{
                  new_password: "",
                  confirm_password: "",
                }}
                validationSchema={ContactSchema}
                onSubmit={(values, {resetForm}) => {
                  {
                    handleResetLink(values, {resetForm});
                  }
                }}
              >
                {({errors, touched, isValid, isSubmitting}) => (
                  <Form>
                    <Grid container spacing={2} className="formMain">
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <label className="reset-new-PasswordLabel">
                          New Password<span style={{color: "red"}}>*</span>
                        </label>
                        <div>
                          <Field
                            className="reset-inputNewPassword"
                            type="Password"
                            name="new_password"
                            margin="normal"
                            placeholder="Enter your New Password"
                            style={{paddingLeft: "12px"}}
                          />
                        </div>
                        <div className="reset-newPasswordError">
                          {errors.new_password && touched.new_password ? (
                            <div>
                              {errors.new_password}
                              {setResetPassowrdRes("")}
                              {setResetPassowrdResErr("")}
                            </div>
                          ) : null}
                        </div>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="confirmPasswordMainGrid"
                        >
                          <label className="reset-confirmPasswordLabel">
                            Confirm Password
                            <span style={{color: "red"}}>*</span>
                          </label>
                          <div>
                            <Field
                              className="reset-inputPassword"
                              type="password"
                              name="confirm_password"
                              margin="normal"
                              placeholder="Re-enter your password"
                              style={{paddingLeft: "12px"}}
                            />
                          </div>
                          <div className="reset-confirmPasswordError">
                            {errors.confirm_password &&
                            touched.confirm_password ? (
                              <div>
                                {errors.confirm_password}
                                {setResetPassowrdRes("")}
                                {setResetPassowrdResErr("")}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                {resetPassowrdRes ? (
                                  <p
                                    className="invitation-areUSure"
                                    style={{marginLeft: "5px", color: "green"}}
                                  >
                                    {" "}
                                    {resetPassowrdRes}
                                  </p>
                                ) : (
                                  <p
                                    className="invitation-areUSure"
                                    style={{marginLeft: "5px", color: "red"}}
                                  >
                                    {" "}
                                    {resetPassowrdResErr}
                                  </p>
                                )}
                                <button
                                  className="reset-requestBtn"
                                  name="submit"
                                  type="submit"
                                >
                                  Reset Password
                                </button>
                              </Grid>
                              <div />
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ResetPassword;