import React, {useEffect, useState} from "react";
import Adminsidebar from "./Adminsidebar";
import "../../src/AdminDashboard/CSS/admintrip.css";
import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  Breadcrumbs,
  Card,
  Container,
  Grid,
  Link,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import {Icon} from "@iconify/react";
import axios from "axios";
import Admintrippopup from "./Admintrippopup";
import tcicon from "./Images/Favicon 25 WB _ DV.webp";
import AdminHeader from "./Components/AdminHeader";

function TripApproval() {
  const [createAdtrTripid, setCreateAdtrTripid] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [statusCounts, setStatusCounts] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchingValue, setsearchingValue] = useState();
  const [noresultsFound, setNoresultsFound] = useState(null);

  console.log(searchingValue,"searchingValue")

  const statusoptions = [
    {label: "Waiting", value: 1},
    {label: "Approved", value: 2},
    {label: "Rejected", value: 3},
  ];

  const handleSearch = (e, statusvalue) => {
    const {value} = e.target;
    if (value) {
      setsearchingValue({
        ...searchingValue,
        traveler_name: value,
      });
      
    } else {
      setsearchingValue({
        ...searchingValue,
        traveler_name: '',
      });
      // viewTripimage()
    }
  };

  const handleStatus=(e,statusvalue)=>{
      setsearchingValue({
        ...searchingValue,
        trip_status: statusvalue?statusvalue.value:"",
      });
  }

  const isSearching = () => {
    let flag = false;
    if (searchingValue) {
      if (searchingValue.traveler_name) flag = true;
      if (searchingValue.trip_status) flag = true;
    }

    return flag;
  };
  const viewTripimage = async current => {
    let domain = "";
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    axios
      .get(
        isSearching()
          ? `${domain}/admin/get-trip-approval/?page=${currentPage}&q=${
              isSearching()
                ? searchingValue.traveler_name
                  ? searchingValue.traveler_name
                  : ""
                : ""
            }&status=${
              isSearching()
                ? searchingValue.trip_status
                  ? searchingValue.trip_status
                  : ""
                :""
            }`
          : `${domain}/admin/get-trip-approval/?page=${currentPage}`
      )
      .then(response => {
        if (isSearching()) {
          setFilteredData(response.data.results);
          setNoresultsFound(null);
        } else {
          setFilteredData(response.data.results.trips);
          setStatusCounts({...response.data.results, trips: null});
        }
        // if (response.data.results.trips) {
        
        //   setFilteredData(response.data.results.trips);
        //   setStatusCounts({...response.data.results, trips: null});
        // } else {
        //   setFilteredData(response.data.results);
        //   setNoresultsFound(null);
        // }



        setData(response.data);
      })
      .catch(error => {
        setNoresultsFound(error.message, "response");
      });
  };


  useEffect(() => {
    viewTripimage();
  }, [currentPage,searchingValue?.trip_status,searchingValue?.traveler_name]);
  // useEffect(() => {
  //   viewTripimage();
  // }, [!searchingValue]);
 
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [modalOpenAdtrpopInfo, setModalOpenAdtrpopInfo] = useState(false);
  const showModalAdtrpopInfo = () => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);
  };

  const popup = item => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);

    setCreateAdtrTripid(item);
  };
  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="admin-trip-main">
        <div style={{borderRight: "groove", minHeight: "90vh"}}>
          <Adminsidebar />
        </div>
        <div style={{width: "100%", height: "auto"}}>
          <div className="admin-trip-heading">Trip Approval</div>
          <div className="admin-subheading">
            <Container className="admin-trip-subheading">
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href=">">
                  Home
                </Link>
                <Typography className="admin-subheading">
                  Trip approval
                </Typography>
              </Breadcrumbs>
            </Container>
          </div>

          <Card style={{width: "95%", margin: "auto"}}>
            <Container>
              <>
                {statusCounts && (
                  <Grid container spacing={2} className="admin-trip-box-main">
                    <Grid item xl={3} lg={3} md={3} xs={3} sm={3}>
                      <Card className="admin-box">
                        <div className="admin-trip-number">
                          {statusCounts.waiting_list}
                        </div>{" "}
                        <div className="admin-box-color">Waiting List</div>
                      </Card>
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} xs={3} sm={3}>
                      <Card className="admin-box">
                        <div className="admin-trip-number">
                          {" "}
                          {statusCounts.approved_list}
                          {""}
                        </div>
                        <div className="admin-box-color">Approved</div>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} xs={3} sm={3}>
                      <Card className="admin-box">
                        <div className="admin-trip-number">
                          {" "}
                          {statusCounts.rejected_list}{" "}
                        </div>{" "}
                        <div className="admin-box-color">Rejected </div>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </>
              <div className="admin-search-main">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    xs={8}
                    sm={8}
                    className="admin-companion-search"
                  >
                    <label htmlFor="search">
                      <TextField
                        className="admin-companion-srch"
                        id="search"
                        variant="outlined"
                        placeholder="Search by Name or email"
                        name="traveler_name"
                        onChange={handleSearch}
                        fullWidth
                        inputProps={{
                          style: {
                            height: "2px",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <Icon
                              onClick={() => {
                                viewTripimage();
                              }}
                              className="admin-icon"
                              icon="ic:round-search"
                              style={{cursor: "pointer"}}
                            />
                          ),
                        }}
                      />
                    </label>
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    xs={4}
                    sm={4}
                    className="admin-companion-status"
                  >
                    <Autocomplete
                      className="admin-trip-stat"
                      name="trip_status"
                      onChange={handleStatus}
                      clearIcon={<ClearIcon />}
                      disablePortal
                      id="combo-box-demo"
                      options={statusoptions}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Status:All"
                          className="custom-notched-outline"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              {noresultsFound ? (
                <Container>
                  <div className="search-results-found-main-div">
                    {" "}
                    <img
                      src="../../Assets/Images/Search illustration.jpg"
                      className="search-img"
                    ></img>
                    <div className="trip-no-results-found">
                      Ups!... no results found
                    </div>
                    <div>
                      No content matched your keyword. Please try searching for
                      something else
                    </div>
                  </div>
                </Container>
              ) : (
                <Container className="admin-trip-tbl">
                  <table className="admin-trip-table">
                    <tr className="admin-trip-border ">
                      <th>Trip Id</th>
                      <th>Traveler Name</th>
                      <th>Traveler Image</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    <tbody>
                      {filteredData.map(item => (
                        <>
                          <tr className="admin-trip-border1">
                            <td>
                              <div className="admin-color">{item.id}</div>
                            </td>

                            <td>
                              <b>{item.traveler_name}</b>
                              <div className="admin-email"> {item.email}</div>
                            </td>

                            <td className="admin-td-image">
                              <img
                                accept="jfif"
                                src={item.photo ? item.photo : tcicon}
                                className="admin-trip-image"
                              />
                            </td>
                            <td className="admin-description">
                              {item.short_description}
                            </td>

                            <td>
                              {item.trip_status === 1
                                ? "Waiting"
                                : item.trip_status === 2
                                ? "Approved"
                                : "Rejected"}
                            </td>
                            <td className="admin-trip-cmicon">
                              <Icon
                                className="admin-trip-icon1"
                                icon="ic:round-remove-red-eye"
                                onClick={() => popup(item)}
                              />
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </Container>
              )}
              <div>
                {modalOpenAdtrpopInfo && (
                  <Admintrippopup
                    createAdtrTripid={createAdtrTripid}
                    setOpenModalpopinfo={showModalAdtrpopInfo}
                    setTrip={viewTripimage}
                  />
                )}

                {!noresultsFound && !modalOpenAdtrpopInfo && data && (
                  <>
                    {(searchingValue?.traveler_name !== undefined ||
                      data.count > 5) && (
                      <Pagination
                        className="admin-trip-bttn"
                        count={Math.ceil(data.count / 5)}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    )}
                  </>
                )}
              </div>
            </Container>
          </Card>
        </div>
      </div>
    </>
  );
}

export default TripApproval;
