import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import {useNavigate, useParams} from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import moment from "moment/moment";
import ScrollTop from "../ScrollTop";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import React, {useEffect, useState} from "react";
import {Icon} from "@iconify/react";
import "../../src/AdminDashboard/CSS/adsmanagementcreate.css";
import Adminsidebar from "./Adminsidebar";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import ToastServive from "react-material-toast";
import {getDomain} from "../domain/Domain";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import axios from "axios";
import AdminHeader from "./Components/AdminHeader";
import UploadImage from "./UploadImage";

const AdsManagementCreate = () => {

  const Navigate = useNavigate();

  // /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  // "special characters  are not allowed"
  var regex = /^(\+?\d{1,3}|\d{1,4})$/gm;
  const expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const englishchar = /^[a-zA-Z ]*$/;
  const today = moment(moment(new Date()).format("DD-MMM-yyyy"));
  const [checkbox, setCheckbox] = useState(false);
  const [countryCodeApiResponse, setCountryCodeApiResponse] = useState();
  const [is_number_focused, setis_number_focused] = useState(false);
  const [codeValue,setCodeValue]=useState("")
  const [ADbackenError,setADbackenError]=useState("")
  const toast = ToastServive.new({
    place: "topRight",
    duration: 5,
    maxCount: 8,
  });
  const [previousAds, setPreviousAds] = useState({
    mobile_ad: "",
    desktop_ad: "",
  });
  const {id} = useParams();
  const [editmode, setEditmode] = useState(id === "new" ? true : false);
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .matches(/^[^\s.][a-zA-Z.]*\s?[a-zA-Z]*$/, "special characters and numbers are not allowed")
      .test("no-leading-space", "Name cannot start with a space", value => {
        if (value) {
          return !value.startsWith(" ");
        }
        return true;
      })
      .min(3, "less than 3 characters not allowed")
      .max(100, "The name should be maximum 100 characters only"),

    company_name: yup
      .string()
      .matches(englishchar, "only English characters allowed"),
    email_id: yup
      .string()
      .required("Email ID is required")
      .matches(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1}?$/,
        "Invalid Email"
      )
      .email("Invalid email"),
    phone_number: yup
      .string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone number not valid")

      .min(4, "Phone number must be at least 4 characters")
      .max(12, "Phone number must be at most 12 characters")
      .test(
        "not-all-zeros",
        "Phone number cannot consist of all zeros",
        value => {
          if (value) {
            return !/^0+$/.test(value); 
          }
          return true;
        }
      ),
    ad_duration: yup
      .string()
      .required("Ad Duration is required")
      .matches(/^[a-zA-Z0-9\s]*$/, "special characters  are not allowed")
      .min(1, "Ad Duration must be at least 1 characters")
      .max(15, "Ad Duration must be at most 15 characters "),
    start_period: yup.string().required("Start Date is required"),
    end_period: yup.string().required("End Date is required"),
    // ad_link: yup
    //   .string()
    //   .matches(regex, "Enter valid link"),
    // ad_price: yup
    //   .string()
    //   .min(3, "Ad price must be at least 3 characters")
    //   .max(15, "Ad price must be at most 15 characters"),

    ad_level: yup.string().required("Ad Level is required"),
    ad_placement: yup.string().required("Ad Placement is required"),
    mobile_ad: yup.mixed().required(`Mobile Ad is required`),
    desktop_ad: yup.mixed().required(`Desktop Ad is required`),
    locations: yup.array().of(
      yup.object().shape({
        country: yup
          .string()
          .required("Country is required")
          .matches(
            /^[a-zA-Z ]*$/,
            "Special characters and numbers are not allowed"
          ),

        state: yup.string().when(["ad_level"], {
          is: ad_level => ad_level === "state" || ad_level === "city",
          then: yup
            .string()
            .required("State is required")
            .matches(
              /^[a-zA-Z ]*$/,
              "Special characters and numbers are not allowed"
            ),
          otherwise: yup.string().nullable(),
        }),
        city: yup.string().when(["ad_level"], {
          is: ad_level => ad_level === "city",
          then: yup
            .string()
            .required("City is required")
            .matches(
              /^[a-zA-Z ]*$/,
              "Special characters and numbers are not allowed"
            ),
          otherwise: yup.string().nullable(),
        }),
      })
    ),
    country_code: yup
      .object()
      .required("Country code is required").nullable()
  });


  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      email_id: "",
      phone_number: "",
      ad_duration: "",
      start_period: "",
      end_period: "",
      ad_level: "",
      ad_placement: "",
      locations: [],
    },
  })
  const {
    register,
    getValues,
    setValue,
    watch,
    reset,
    control,
    formState: {errors},
    handleSubmit,
  } = methods;

  const values = getValues();
  console.log(values,"form-values")
  console.log(errors,"form-errors")
  const watchFields = watch();


  const onSubmit = values => {
    const array = [];
    const toDeleteKey=["created_on","updated_on","ads_id","is_deleted","on_hold"]
    values.locations.map((item,i) => {
      if(item[toDeleteKey[i]])
        delete item.created_on;
      array.push(item);
    });
    values.locations = array;
    if (typeof values.desktop_ad === "string") {
      delete values.desktop_ad;
    }
    if (typeof values.mobile_ad === "string") {
      delete values.mobile_ad;
    }
    values["start_period"] = moment(values.start_period).format("YYYY-MM-DD");
    values["end_period"] = moment(values.end_period).format("YYYY-MM-DD");
    values["country_code"] = values.country_code.phone_code;
    const newdata = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === "locations") {
        newdata.append(key, JSON.stringify(value));
      } else {
        newdata.append(key, value);
      }
    });
    const domain = getDomain();
    delete values.website;
    delete values.ad_link;
    delete values.created_on;
    delete values.updated_on;
    delete values.on_hold;
    delete values.is_deleted;
    delete values.ads_id;

    let final = {};
    if (values.desktop_ad || values.mobile_ad) {
      const editformdata = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (key === "locations") {
          editformdata.append(key, JSON.stringify(value));
        } else if (key === "id") {
          delete values.id;
        } else if (key === "is_deleted") {
          delete values.is_deleted;
        } else {
          editformdata.append(key, value);
        }
      });
      final = editformdata;
    } else {
      final = values;
    }

    if (id !== "new") {
      axios
        .patch(`${domain}/admin/ads/${id}/`, newdata)
        .then(response => {
          console.log(response, "response");
          const msg = toast.success(response.data.message, () => {});
          Navigate("/admin/admin-ads-management");
        })
        .catch(error => {
          console.log(error.response.data.message,"ADbackenError")
          if(error.response.data.message){
          setADbackenError(error.response.data.message)
          }
        });
    } else {
      delete values.created_on;
      delete values.website;
      delete values.ad_link;

      delete values.updated_on;
      axios
        .post(`${domain}/admin/ads-post/`, newdata)
        .then(response => {
          console.log(response, "response");
          const msg = toast.success(response.data.message, () => {});
          Navigate("/admin/admin-ads-management");
        })
        .catch(error => {
          console.log(error.response.data.message,"ADbackenError")
          if(error.response.data.message){
            setADbackenError(error.response.data.message)
            }
        });
    }
  };


  const {fields, remove, append} = useFieldArray({
    control,
    name: "locations",
  });


  const [openTo, setOpenTo] = React.useState(false);
  const handleOpenTo = () => setOpenTo(true);
  const handleCloseTo = () => setOpenTo(false);

  const [openFrom, setOpenFrom] = React.useState(false);
  const handleOpenFrom = () => setOpenFrom(true);
  const handleCloseFrom = () => setOpenFrom(false);

  const getCountryCodeApi=async()=>{
    let res
 await  axios.get(`${getDomain()}/country-code/`).then(response => {
      setCountryCodeApiResponse(response.data.response);
      res= response.data.response
    });
return res
  }

  useEffect(() => {
    let countryCode=[]
    getCountryCodeApi().then((res)=>{
      countryCode= res
    })
   
    const getAdsData=async()=>{
      if (id !== "new") {
      await  axios.get(`${getDomain()}/admin/ads/${id}/`).then(response => {
          let obj = {
            mobile_ad: response.data.mobile_ad,
            desktop_ad: response.data.desktop_ad,
          };
          const EnteredCode=countryCode.filter((code,i)=>{
            return response.data.country_code===code.phone_code
          })
          setPreviousAds(obj);
          reset({...response.data,country_code:EnteredCode[0]});
        });
      } else {
        setCheckbox(true);
      }
    }
    getAdsData()
  }, []);


  const StartperiodValidation = () => {
    let Sdate;
    if (values.end_period) {
      Sdate = moment(values.end_period);
    }

    return Sdate;
  };

  const findErrorMessage = (name, index) => {
    let message = "";
    if (errors["locations"] && errors["locations"].length > 0) {
      if (errors["locations"][index]) {
        if (errors["locations"][index][`${name}`]) {
          message = errors["locations"][index][`${name}`]["message"];
        }
      }
    }

    return message;
  };

  const textFieldStyle= {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: "#1ec28b",
      },
    },
    "& .MuiInputLabel-root": {color: "green"}, //styles the label
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {borderColor: "#1ec28b"},
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "#1ec28b",
      },
    },
  }

  const countryCode_style= {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "none",
      },
    },
    "& .MuiInputLabel-root": {color: "green"}, //styles the label
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {border: "none"},
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        border: "none",
      },
    },
  }

  const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
  }));


  const StyledDay = styled(PickersDay)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.secondary.dark
        : theme.palette.secondary.light,
  }));





  return (
    <ScrollTop>
      <AdminHeader></AdminHeader>
      <div>
        <div className="dbsql-profile-main">
          <div style={{borderRight: "groove", minHeight: "90vh"}}>
            <Adminsidebar />
          </div>
          <Container>
            <div className="admindashboard-heading">Ads Management</div>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              className="ads-management-breadcrumbs"
            >
              {/* {breadcrumbs} */}
              <Link className="ads-manage-home">Home</Link>
              <Typography>Ads Management</Typography>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography className="ads-management-subheading">
                  {id !== "new" ? "Auxtomate" : "New Ad"}
                </Typography>
              </Breadcrumbs>
            </Breadcrumbs>
            <Card className="ads-manage-create-form">
              <Container>
                <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {id !== "new" && (
                    <div className="ads-manage-edit-main-div">
                      <Button
                        className={
                          editmode
                            ? "ads-manage-edit-btn-disabled"
                            : "ads-manage-edit-btn"
                        }
                        disabled={editmode}
                        onClick={() => {
                          setEditmode(true);
                        }}
                      >
                        <span>
                          <Icon
                            icon="ic:baseline-mode-edit"
                            className="ads-manage-edit-icon"
                          />
                        </span>
                        Edit
                      </Button>
                    </div>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <div>
                        <div className="ads-manage-name">
                          Name<span className="ads-manage-name-span">*</span>
                        </div>
                      </div>
                      <div>
                        <Controller
                          name="name"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                            <TextField
                              disabled={!editmode}
                              className="ads-manage-create-name"
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name"
                              margin="normal"
                              {...register("name")}
                              error={errors.name ? true : false}
                              sx={textFieldStyle}
                              inputProps={{                            
                                  autocomplete: 'off', 
                              }}
                            />
                          )}
                        />
                        <p className="ads-management-error-message">
                          {" "}
                          {errors.name && errors.name.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <div>
                        <div className="ads-manage-name"> Company Name</div>
                      </div>
                      <div>
                        <Controller
                          name="company_name"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                            <TextField
                              disabled={!editmode}
                              className="ads-manage-create-company-name"
                              type="text"
                              name="company_name"
                              id="company_name"
                              placeholder="Company Name"
                              margin="normal"
                              {...register("company_name")}
                              error={errors.company_name ? true : false}
                              sx={textFieldStyle}
                              inputProps={{                            
                                autocomplete: 'off', 
                            }}
                            />
                          )}
                        />
                        <p className="ads-management-error-message">
                          {" "}
                          {errors.company_name && errors.company_name.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <div>
                        <div className="ads-manage-name">Website name</div>
                      </div>
                      <div>
                        <Controller
                          name="website"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                            <TextField
                              disabled={!editmode}
                              className="ads-manage-create-website-name"
                              type="text"
                              name="website"
                              id="website"
                              margin="normal"
                              placeholder="Website Name"
                              {...register("website")}
                              error={errors.website ? true : false}
                              sx={textFieldStyle}
                              inputProps={{                            
                                autocomplete: 'off', 
                            }}
                            />
                          )}
                        />
                        <p className="ads-management-error-message">
                          {" "}
                          {errors.website && errors.website.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <div>
                        <div className="ads-manage-name">
                          Email ID
                          <span className="ads-manage-name-span">*</span>
                        </div>
                      </div>
                      <div>
                        <Controller
                          name="email_id"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                            <TextField
                              disabled={!editmode}
                              className="ads-manage-create-email"
                              type="text"
                              name="email_id"
                              id="email_id"
                              placeholder="Email ID"
                              margin="normal"
                              {...register("email_id")}
                              error={errors.email_id ? true : false}
                              sx={textFieldStyle}
                              inputProps={{                            
                                autocomplete: 'off', 
                            }}
                            />
                          )}
                        />
                        <p className="ads-management-error-message">
                          {errors.email_id && errors.email_id.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="ads-manage-phone-num-grid"
                    >
                      <div>
                        <div className="ads-manage-name">
                          Phone Number
                          <span className="ads-manage-name-span">*</span>
                        </div>
                      </div>
                      <div className="ads-manage-phone">
                        <Grid
                        item xl={12} lg={12} md={12} xs={12} sm={12}
                        onFocus={() => setis_number_focused(true)}
                        onBlur={() => setis_number_focused(false)}
                          className={"country-code-grid"}
                          style={{
                            border: is_number_focused ? 
                            ((errors?.phone_number&&errors?.country_code)?'2px solid #1ec28b':'2px solid #1ec28b') 
                            : ((errors?.phone_number&&errors?.country_code)?'1px solid #de6363':'1px solid #1ec28b') ,
                            outline: 'none',
                          }}
                        >
                           <Controller
                          name="country_code"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                          <Autocomplete
                            disabled={!editmode}
                            id="country_code"
                            name="country_code"
                              // onClose={() =>{
                              //   setCodeValue("");
                              // }}
                              onInputChange={(e, value, reason) => {
                                setCodeValue(value);
                                if (!value) {
                                  setCodeValue("")
                                 }
                              }}
                              onChange={(e,value)=>{
                                    setValue("country_code",value,{shouldValidate:true})
                              }}
                            className="ads-manage-textfield"
                            value={value ? value.phone_code : null}
                            inputValue={codeValue}
                            options={countryCodeApiResponse}
                            autoHighlight
                            getOptionLabel={
                              option => option.name || option
                            }
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{"& > img": { flexShrink: 0}}}
                                {...props}
                              >
                                {option.name} ({option.phone_code})
                              </Box>
                            )}
                            renderInput={params => (
                              <div>
                                <TextField
                                  error={errors.country_code ? true : false}
                                  {...params}
                                  sx={countryCode_style}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                />
                              </div>
                            )}
                          />
                          )}/>
                             <Controller
                          name="phone_number"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                          <TextField
                            disabled={!editmode}
                            id="phone_number"
                            className="ads-manage-phone-second-textfield"
                            placeholder="Enter Phone Number"
                            name="phone_number"
                            margin="normal"
                            {...register("phone_number")}
                            error={errors.phone_number ? true : false}
                            sx={countryCode_style}
                            inputProps={{                            
                              autocomplete: 'off', 
                          }}
                          />)}></Controller>
                        </Grid>
                      </div>
                      <p className="ads-management-error-message">
                        {errors.phone_number
                        ?errors.phone_number.message
                        : (errors.country_code?errors.country_code.message:null)}
                      </p>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <div>
                        <div className="ads-manage-name">Ad Link</div>
                      </div>
                      <div>
                        <Controller
                          name="ad_link"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                            <TextField
                              disabled={!editmode}
                              className="ads-manage-create-ad-link"
                              type="text"
                              name="ad_link"
                              id="ad_link"
                              placeholder="Ad Link"
                              margin="normal"
                              {...register("ad_link")}
                              error={errors.ad_link ? true : false}
                              sx={textFieldStyle}
                              inputProps={{                            
                                autocomplete: 'off', 
                            }}
                            />
                          )}
                        />
                        <p className="ads-management-error-message">
                          {errors.ad_link && errors.ad_link.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <div>
                        <div className="ads-manage-name">Ad Price</div>
                      </div>
                      <div>
                        <Controller
                          name="ad_price"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                            <TextField
                              disabled={!editmode}
                              className="ads-manage-create-ad-price"
                              type="text"
                              name="ad_price"
                              id="ad_price"
                              placeholder="Ad Price"
                              margin="normal"
                              {...register("ad_price")}
                              error={errors.ad_price ? true : false}
                              sx={textFieldStyle}
                              inputProps={{                            
                                autocomplete: 'off', 
                            }}
                            />
                          )}
                        />
                        <p className="ads-management-error-message">
                          {errors.ad_price && errors.ad_price.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <div>
                        <div className="ads-manage-name">
                          Ad Duration
                          <span className="ads-manage-name-span">*</span>
                        </div>
                      </div>
                      <div>
                        <Controller
                          name="ad_duration"
                          control={control}
                          render={({field: {onChange, value, name}}) => (
                            <TextField
                              disabled={!editmode}
                              className="ads-manage-create-ad-duration"
                              type="text"
                              name="ad_duration"
                              id="ad_duration"
                              placeholder="Ad Duration"
                              margin="normal"
                              {...register("ad_duration")}
                              error={errors.ad_duration ? true : false}
                              sx={textFieldStyle}
                              inputProps={{                            
                                autocomplete: 'off', 
                            }}
                            />
                          )}
                        />
                        <p className="ads-management-error-message">
                          {errors.ad_duration && errors.ad_duration.message}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <div>
                        <div className="ads-manage-name">
                          Start Date
                          <span className="ads-manage-name-span">*</span>
                        </div>
                      </div>
                      <Controller
                        name="start_period"
                        control={control}
                        render={({field: {ref, value, name, ...rest}}) => (
                          <LocalizationProvider
                            style={{backgroundColor: "white"}}
                            dateAdapter={AdapterMoment}
                          >
                            <DatePicker
                                 slots={{
                                  openPickerIcon: <Icon icon="uiw:date" style={{color:"#ff8d8d",fontSize:30}} /> ,
                                  openPickerButton: StyledButton,
                                  day: StyledDay,
                                }}
                                slotProps={{
                                  openPickerIcon: { fontSize: 'large' },
                                  openPickerButton: { color: 'secondary' },
                                  textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'secondary',
                                  },
                                }}
                              PaperProps={{
                                sx: {backgroundColor: "white !important"},
                              }}
                              name="start_period"
                              disabled={!editmode}
                              minDate={today}
                              maxDate={StartperiodValidation()}
                              inputFormat="MMM DD, yyyy"
                              value={value ? value : null}
                              open={openTo}
                              onOpen={handleOpenTo}
                              onClose={handleCloseTo}
                              onChange={newValue => {
                                setValue(name, newValue.format("YYYY-MM-DD"));
                              }}
                              renderInput={params => {
                                return (
                                  <TextField
                                    {...params}
                                    name="dateFrom"
                                    className="xxx"
                                   sx={{ 
                                    ...textFieldStyle,
                                    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium ": {
                                      
                                        display: "none",
                                    }}}
                                   
                                    InputProps={{
                                      ...params.InputProps,
                                      openPickerIcon:<Icon icon="uiw:date" style={{color:"#ff8d8d",fontSize:30}} />,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                           <IconButton className="datePicker-icon-button">
                                            <Icon icon="uiw:date" style={{color:"#ff8d8d",fontSize:25}} onClick={()=>handleOpenTo()}/>                                            <EventIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    {...register("start_period")}
                                    error={errors.start_period ? true : false}
                                    fullWidth
                                    style={{backgroundColor: "white"}}
                                  />
                                );
                              }}
                              {...rest}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <p className="ads-management-error-message">
                        {errors.start_period && errors.start_period.message}
                      </p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <div>
                        <div className="ads-manage-name">
                          End/Expiry Date
                          <span className="ads-manage-name-span">*</span>
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Controller
                          name="end_period"
                          control={control}
                          render={({field: {ref, value, name, ...rest}}) => (
                            <DatePicker
                              // disablePast
                              inputFormat="MMM DD, yyyy"
                              value={value ? value : null}
                              PaperProps={{
                                sx: {backgroundColor: "white !important"},
                              }}
                              open={openFrom}
                              disabled={!editmode}
                              onOpen={handleOpenFrom}
                              onClose={handleCloseFrom}
                              onChange={newValue => {
                                setValue(name, newValue);
                              }}
                              minDate={moment(
                                moment(values.start_period).format(
                                  "DD-MMM-yyyy"
                                )
                              )}
                              renderInput={params => {
                                return (
                                  <TextField
                                    {...params}
                                    className="xxx"
                                    fullWidth
                                    sx={{ 
                                      ...textFieldStyle,
                                      "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium ": {
                                        
                                          display: "none",
                                      }}}
                                     
                                      InputProps={{
                                        ...params.InputProps,
                                        openPickerIcon:<Icon icon="uiw:date" style={{color:"#ff8d8d",fontSize:30}} />,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                             <IconButton className="datePicker-icon-button">
                                              <Icon icon="uiw:date" style={{color:"#ff8d8d",fontSize:25}} onClick={()=>handleOpenFrom()}/>                                            <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    {...register("end_period")}
                                    error={errors.end_period ? true : false}
                                  />
                                );
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <p className="ads-management-error-message">
                        {errors.end_period && errors.end_period.message}
                      </p>
                    </Grid>
                  </Grid>
                  <div className="ads-manage-title">Schedule</div>
                  {id !== "new" && (
                    <Grid container spacing={2}>
                      <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                        <div>
                          <div className="ads-manage-name">Created on</div>
                        </div>
                        <div>
                          <Controller
                            name="created_on"
                            control={control}
                            render={({field: {onChange, value, name}}) => (
                              <TextField
                                disabled={true}
                                className="ads-manage-create-created-on"
                                type="text"
                                name="created_on"
                                id="created_on"
                                placeholder="Created on"
                                margin="normal"
                                {...register("created_on")}
                                error={errors.created_on ? true : false}
                                sx={textFieldStyle}
                                inputProps={{                            
                                  autocomplete: 'off', 
                              }}
                              />
                            )}
                          />
                          <p className="ads-management-error-message">
                            {errors.created_on && errors.created_on.message}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                        <div>
                          <div className="ads-manage-name">Updated on</div>
                        </div>
                        <div>
                          <Controller
                            name="updated_on"
                            control={control}
                            render={({field: {onChange, value, name}}) => (
                              <TextField
                                disabled={true}
                                className="ads-manage-create-updated-on"
                                type="text"
                                name="updated_on"
                                id="updated_on"
                                margin="normal"
                                placeholder="Updated on"
                                {...register("updated_on")}
                                error={errors.updated_on ? true : false}
                                sx={textFieldStyle}
                                inputProps={{                            
                                  autocomplete: 'off', 
                              }}
                              />
                            )}
                          />
                          <p className="ads-management-error-message">
                            {errors.updated_on && errors.updated_on.message}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  <Box className="ads-manage-ads-main-div">
                    <Container>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                        <div>
                          <div className="ads-manage-title ">
                            Ad Level
                            <span className="ads-manage-name-span">*</span>
                          </div>
                        </div>
                        <div>
                          <Controller
                            control={control}
                            name="ad_level"
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                              <Select
                                disabled={!editmode}
                                // required
                                fullWidth
                                placeholder="Choose your Locations"
                                margin="dense"
                                id="ad_level"
                                name="ad_level"
                                onChange={e => {
                                  const {name, value} = e.target;
                                  let obj;
                                  if (value === "country")
                                    obj = [{country: ""}];
                                  else if (value === "state")
                                    obj = [{country: "", state: ""}];
                                  else
                                    obj = [
                                      {
                                        country: "",
                                        state: "",
                                        city: "",
                                      },
                                    ];
                                  setValue("locations", obj);
                                  setValue("ad_level", e.target.value);
                                }}
                                value={value}
                                // {...register("country")}
                                error={errors.ad_level ? true : false}
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1ec28b",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1ec28b",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
                                    borderColor: "#1ec28b",
                                   },
                                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1ec28b",
                                    },
                                  "& .MuiSvgIcon-root": {
                                    color: "#ff8d8d",
                                  },
                                }}
                                inputProps={{                            
                                  autocomplete: 'off', 
                              }}
                              >
                                <MenuItem value="country">Country</MenuItem>
                                <MenuItem value="state">State</MenuItem>
                                <MenuItem value="city">City</MenuItem>
                              </Select>
                            )}
                          ></Controller>
                          <p className="ads-management-error-message">
                            {errors.ad_level && errors.ad_level.message}
                          </p>
                        </div>
                      </Grid>
                      <Container style={{display: "flex"}}>
                        <div style={{width: "100%"}}>
                          {fields.map((item, index) => {
                            return (
                              <Grid
                                container
                                spacing={2}
                                style={{display: "flex", width: "100%"}}
                              >
                                <Grid
                                  item
                                  xl={4}
                                  lg={4}
                                  md={4}
                                  xs={4}
                                  sm={4}
                                  style={{width: "100%"}}
                                >
                                  <div>
                                    <Controller
                                      name={`locations[${index}].country`}
                                      control={control}
                                      render={({
                                        field: {onChange, value, name},
                                      }) => (
                                        <TextField
                                          disabled={!editmode}
                                          onChange={onChange}
                                          value={value}
                                          name={name}
                                          className="ads-manage-create-country"
                                          type="text"
                                          id="country"
                                          placeholder="Enter your Country"
                                          margin="normal"
                                          {...register(
                                            `locations[${index}].country`
                                          )}
                                          sx={textFieldStyle}
                                          inputProps={{                            
                                            autocomplete: 'off', 
                                        }}
                                        />
                                      )}
                                    />
                                    <p className="ads-management-error-message">
                                      {findErrorMessage("country", index)}
                                    </p>
                                  </div>
                                </Grid>
                                {(values.ad_level === "city" ||
                                  values.ad_level === "state") && (
                                  <Grid item xl={4} lg={4} md={4} xs={4} sm={4}>
                                    <div style={{width: "100%"}}>
                                      <Controller
                                        name={`locations[${index}].state`}
                                        control={control}
                                        render={({
                                          field: {onChange, value, name},
                                        }) => (
                                          <TextField
                                            disabled={!editmode}
                                            onChange={onChange}
                                            value={value}
                                            name={name}
                                            className="ads-manage-create-state"
                                            type="text"
                                            id="state"
                                            placeholder="Enter your State"
                                            margin="normal"
                                            {...register(
                                              `locations[${index}].state`
                                            )}
                                            sx={textFieldStyle}
                                            inputProps={{                            
                                              autocomplete: 'off', 
                                          }}
                                          />
                                        )}
                                      />
                                      <p className="ads-management-error-message">
                                        {findErrorMessage("state", index)}
                                      </p>
                                    </div>
                                  </Grid>
                                )}
                                {values.ad_level === "city" && (
                                  <Grid item xl={4} lg={4} md={4} xs={4} sm={4}>
                                    <div style={{width: "100%"}}>
                                      <Controller
                                        name={`locations[${index}].city`}
                                        control={control}
                                        render={({
                                          field: {onChange, value, name},
                                        }) => (
                                          <TextField
                                            disabled={!editmode}
                                            onChange={onChange}
                                            value={value}
                                            name={name}
                                            className="ads-manage-create-city"
                                            type="text"
                                            id="city"
                                            placeholder="Enter your City"
                                            margin="normal"
                                            {...register(
                                              `locations[${index}].city`
                                            )}
                                            sx={textFieldStyle}
                                            inputProps={{                            
                                              autocomplete: 'off', 
                                          }}
                                          />
                                        )}
                                      />
                                      <p className="ads-management-error-message">
                                        {findErrorMessage("city", index)}
                                      </p>
                                    </div>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          })}
                        </div>
                        {fields.length > 1 && (
                          <div className="ads-manage-cancel-icon">
                            <Icon
                              icon="icons8:cancel"
                              onClick={() => {
                                remove(fields.length - 1);
                              }}
                            />
                          </div>
                        )}
                      </Container>
                      {editmode && (
                        <div>
                          {fields.length > 0 && (
                            <div className="ads-management-add-button">
                              <Button
                                onClick={() => {
                                  append({
                                    country: "",
                                    state: "",
                                  });
                                }}
                                className="ads-mange-button"
                              >
                                <span>
                                  <Icon
                                    icon="ic:round-plus"
                                    className="ads-management-icon"
                                  />
                                </span>
                                &nbsp;{" "}
                                <span style={{textTransform: "capitalize"}}>
                                  A
                                </span>
                                dd
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </Container>
                  </Box>
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <div>
                      <div className="ads-manage-title">
                        Ad Placement
                        <span className="ads-manage-name-span">*</span>
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name="ad_placement"
                      defaultValue=""
                      render={({field: {onChange, value}}) => (
                               <Select
                          disabled={!editmode}
                          fullWidth
                          focused
                          margin="dense"
                          id="ad_placement"
                          name="ad_placement"
                          onChange={e => {
                            setValue("ad_placement", e.target.value);
                          }}
                          value={value}
                          placeholder="Select Location"
                          {...register("ad_placement")}
                          error={errors.ad_placement ? true : false}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1ec28b",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1ec28b",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
                                borderColor: "#1ec28b",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#ff8d8d",
                            },
                          }}
                        >
                          <MenuItem value="homepage_banner_ad1">
                            Homepage Banner ad1
                          </MenuItem>
                          <MenuItem value="homepage_banner_ad2">
                            Homepage Banner ad2
                          </MenuItem>
                          <MenuItem value="footer_ad1">Footer ad1</MenuItem>
                          <MenuItem value="footer_ad2">Footer ad2</MenuItem>
                          <MenuItem value="footer_ad3">Footer ad3</MenuItem>
                          <MenuItem value="student_community_ad">
                            Student Community ad
                          </MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      
                      )}
                    ></Controller>
                    <p className="ads-management-error-message">
                      {errors.ad_placement && errors.ad_placement.message}
                    </p>
                  </Grid>
                  {id !== "new" && (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        xs={3}
                        sm={3}
                        className="ads-management-previous ads"
                      >
                        <div className="ads-manage-pre-ads">Previous Ads</div>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                        {editmode && (
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={Boolean(checkbox)}
                                  value={Boolean(checkbox)}
                                />
                              }
                              onChange={e => {
                                const {value} = e.target;
                                console.log(value, "check");
                                setValue("desktop_ad", "");
                                setValue("mobile_ad", "");
                                setCheckbox(!checkbox);
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#1ec28b",
                                  },
                                },
                                "& .MuiInputLabel-root": {color: "#1ec28b"}, //styles the label
                                "& .MuiOutlinedInput-root": {
                                  "& > fieldset": {borderColor: "#1ec28b"},
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": {
                                    borderColor: "#1ec28b",
                                  },
                                },
                              }}
                            />
                          </FormGroup>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <div>
                    <Grid>
                      {id !== "new" && (
                        <div className="ads-management-ads-disabled">
                          Desktop Ad
                        </div>
                      )}

                      {previousAds.desktop_ad && (
                        <Grid className="ads-manage-create-upload-ads">
                          <div className="ads-manage-create-desktop-img">
                            <img
                              className={
                                !editmode || checkbox
                                  ? "ads-manage-with-disabled"
                                  : "ads-manage-without-disabled"
                              }
                              src={
                                typeof previousAds.desktop_ad === "object"
                                  ? URL.createObjectURL(previousAds.desktop_ad)
                                  : previousAds.desktop_ad
                              }
                              {...register("desktop_ad")}
                              error={errors.desktop_ad ? true : false}
                            ></img>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Grid>
                      {id !== "new" && (
                        <div className="ads-management-ads-disabled">
                          Mobile Ad
                        </div>
                      )}
                      {previousAds.mobile_ad && (
                        <Grid className="ads-manage-create-upload-ads">
                          <div className="ads-manage-create-desktop-img">
                            <img
                              className={
                                !editmode || checkbox
                                  ? "ads-manage-with-disabled"
                                  : "ads-manage-without-disabled"
                              }
                              src={
                                typeof previousAds.mobile_ad === "object"
                                  ? URL.createObjectURL(previousAds.mobile_ad)
                                  : previousAds.mobile_ad
                              }
                              {...register("mobile_ad")}
                              error={errors.mobile_ad ? true : false}
                            ></img>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  {checkbox && (
                    <div>
                      <div>
                      <div className="ads-manage-name">
                          Upload desktop ad
                          <span className="ads-manage-name-span">*</span>
                      </div>
                        <UploadImage 
                        pageId={id}
                        isEdit={editmode}
                        dev={"desk"}
                        name="desktop_ad"
                        />
                      </div>
                       <div>
                       <div className="ads-manage-name">
                          Upload mobile ad
                          <span className="ads-manage-name-span">*</span>
                        </div>
                        <UploadImage 
                        pageId={id}
                        isEdit={editmode}
                        dev={"mobile"}
                        name="mobile_ad"
                        />
                       </div>
                    </div>
                  )}
                  <span style={{color:"red"}}>{ADbackenError?ADbackenError:''}</span>
                  {editmode && (
                    <div className="ads-manage-post-main-div">
                      <button className="ads-manage-post-button" type="submit">
                      SAVE
                      </button>
                    </div>
                  )}
                </form>

                </FormProvider>
              </Container>
            </Card>
            <div className="ads-management-new-back-page">
              <Icon
                icon="ic:round-arrow-back"
                onClick={() => {
                  Navigate(`/admin/admin-ads-management`);
                }}
                to="/admin/admin-ads-management"
                style={{color: "#1ec28b", spacing: "2"}}
              />
              <div
                onClick={() => {
                  Navigate(`/admin/admin-ads-management`);
                }}
                to="/admin/admin-ads-management"
              >
                Back to Ad Management Page
              </div>
            </div>
          </Container>
        </div>
      </div>
    </ScrollTop>
  );
};
export default AdsManagementCreate;
