import React, {useEffect} from "react";
import Adminsidebar from "../Adminsidebar";
import AdminHeader from "../Components/AdminHeader";
import {Icon} from "@iconify/react";
import "../../AdminDashboard/Member/managemembers.css";
import ManagePopup from "./ManagePopup";
import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Pagination,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {Link} from "react-router-dom";
import {Select} from "antd";
import {useState} from "react";
import axios from "axios";
import usePagination from "@mui/material/usePagination/usePagination";

const ManageMembers = () => {
  const [switchdata, setSwitchData] = useState();
  const [holdOpen, setHoldOpen] = useState(false);
  const [noresultsFound, setNoresultsFound] = useState(null);
  const [counts, setCounts] = useState({});
  const [holdId, setHoldId] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [holdValue, setHoldValue] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [createAdtrTripid, setCreateAdtrTripid] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [modalOpenAdtrpopInfo, setModalOpenAdtrpopInfo] = useState(false);
  const showModalAdtrpopInfo = () => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);
  };
  const holdClickOpen = (id, holdVal) => {
    setHoldOpen(true);
    setHoldId(id);
    setHoldValue(holdVal);
  };

  const statusoptions = [
    {label: "Active", value: "True"},
    {label: "On Hold", value: "False"},
  ];

  const popup = clickedData => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);

    setCreateAdtrTripid(clickedData);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    Managemember(value);
  };
  const handleSearch = (event, statusValue) => {
    const {full_name, Is_active, value} = event.target;
    if (statusValue) {
      setStatusValue(statusValue.value);
    }else{
      setStatusValue();
    }
  };
  const nameSearch = event => {
    const {value} = event.target;
    setSearchValue(value);
  };

  console.log(filteredData, "filterData..");

  const isSearching = () => {
    let flag = false;
    if (searchValue) flag = true;
    if (statusValue) flag = true;

    return flag;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledeleteClose = () => {};
  // const [deleteId,setDeleteId] =useState();

  // const getDomain = () => {
  //   let domain = "";
  //   console.log(process.env, "domain env", window.location.hostname);
  //   if (window.location.hostname.trim() === "localhost") {
  //     domain = process.env.REACT_APP_LOCAL_API;
  //   } else if (window.location.hostname.trim() === "54.185.6.32") {
  //     domain = process.env.REACT_APP_UAT_API;
  //   } else if (
  //     window.location.hostname.trim() === "uat.desitravelcompanion.com"
  //   ) {
  //     domain = process.env.REACT_APP_PROD_UAT_API;
  //   } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
  //     domain = process.env.REACT_APP_PROD_API;
  //   } else if (
  //     window.location.hostname.trim() === "www.desitravelcompanion.com"
  //   ) {
  //     domain = process.env.REACT_APP_PROD_API;
  //   }
  //   return domain;
  // };

  const deleteCard = async id => {
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    axios.delete(`${domain}/delete/user/${deleteId}`).then(response => {
      console.log(response.data.message, "delete ad");
      Managemember();
      setOpen(false);
    });

    // try {
    //   let domain = getDomain();
    //   await axios.delete(`${domain}/delete/user/${deleteId}`);
    //   setOpen(false);
    //   Managemember();
    // } catch (error) {
    //   console.error("Error deleting user:", error.message);
    // }
  };
  // console.log(deleteId,"deleteId")
  const handleClickOpen = id => {
    setOpen(true);
    setDeleteId(id);
  };

  let page = 1;
  const Managemember = async () => {
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(isSearching(), statusValue, "isSearching()");
    axios
      .get(
        isSearching()
          ?statusValue? `${domain}/admin/manage-members/?search=${searchValue}&status=${statusValue}`:searchValue?`${domain}/admin/manage-members/?search=${searchValue}`:`${domain}/admin/manage-members/?page=${currentPage}`
          : `${domain}/admin/manage-members/?page=${currentPage}`
      )
      .then(response => {
        if (isSearching()) {
          console.log(response.data.results.data, "searchresponse");
          setFilteredData(response.data.results.data);
          setNoresultsFound(null);
        } else {
          setFilteredData(response.data.results.data);
          setCounts({
            total: response.data.count,
            active_count: response.data.results.active_count,
            holding_count: response.data.results.holding_count,
          });
        }

        setData(response.data);
        console.log(response.data.results, "counts");
      })
      .catch(error => {
        setNoresultsFound(error.message, "response");
      });
  };

  const Switchhandler = pk => {
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    axios
      .patch(
        `http://uat-api.desitravelcompanion.com/admin/manage-members/update/${holdId}/`,
        {
          is_active: !holdValue,
          // is_active: !switchdata.is_active,
        }
      )
      .then(response => {
        setHoldValue(response.data.is_active);
        // setHoldValue(prevHoldValue => !prevHoldValue);
        setCurrentPage(currentPage);
        Managemember();
        console.log(response.data, "onhold");
      })
      .catch(err => {
        console.error("Error updating user status:", err.message);
      });
    setHoldOpen(false);
  };

  // useEffect(() => {
  //   Managemember();
  // }, [currentPage, searchValue.statusValue, statusValue]);
  useEffect(() => {
    Managemember();
  }, [currentPage, searchValue, statusValue]);

  const {items} = usePagination({
    count: 2,
  });
  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="dbsql-profile-main">
        <div style={{borderRight: "groove", minHeight: "100vh"}}>
          <Adminsidebar />
        </div>

        <Container>
          <div className="manage-members-heading">Manage Member</div>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className="manage-memebers-subhead"
          >
            {/* {breadcrumbs} */}
            <div style={{marginBottom: "3px"}}>
              <Link to={{}} className="manage-member-home">
                Home
              </Link>
            </div>
            <Typography className="manage-members-subheading">
              Manage Member
            </Typography>
          </Breadcrumbs>
          <div>
            <div className="manage-member-addbutton-main">
              <Button
                component={Link}
                to="/admin/add-member"
                className="manage-member-add-button"
              >
                {" "}
                <span style={{fontSize: "12", marginTop: "-2px"}}>
                  <Icon
                    icon="ic:round-plus"
                    className="manage-member-add-icon"
                  />
                </span>
                &nbsp; ADD MEMBER
              </Button>
            </div>
          </div>
          <Card>
            <Container>
              <Grid
                container
                spacing={2}
                className="manage-members-card-maingrid"
              >
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  xs={3}
                  sm={3}
                  style={{marginTop: 40, columnGap: 30}}
                >
                  <Card className="manage-members-card">
                    <div className="manage-members-number">
                      {" "}
                      {counts ? counts.total : ""}
                    </div>
                    <div className="manage-members-content-members">
                      No. of Members{" "}
                    </div>
                  </Card>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  xs={3}
                  sm={3}
                  style={{marginTop: 40}}
                >
                  <Card className="manage-members-card">
                    <div className="manage-members-number">
                      {" "}
                      {counts ? counts.active_count : ""}
                    </div>
                    <div className="manage-members-content-active">
                      Active Members
                    </div>
                  </Card>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  xs={3}
                  sm={3}
                  style={{marginTop: 40}}
                >
                  <Card className="manage-members-card">
                    <div className="manage-members-number">
                      {" "}
                      {counts ? counts.holding_count : ""}
                    </div>
                    <div className="manage-members-content-holding">
                      Holding
                    </div>
                  </Card>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="manage-member-search-main-grid"
              >
                <Grid item xl={8} lg={8} md={8} xs={8} sm={8}>
                  <div>
                    <TextField
                      className="manage-member-search-feild"
                      id="standard-bare"
                      variant="outlined"
                      name="full_name"
                      placeholder="Search by Name"
                      onChange={nameSearch}
                      fullWidth
                      inputProps={{
                        style: {
                          height: 4,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <Icon
                            onClick={() => {
                              Managemember();
                            }}
                            className="manage-member-searchicon"
                            icon="ic:round-search"
                          />
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  xs={4}
                  sm={4}
                  className="admin-companion-status"
                >
                  <Autocomplete
                    className="admin-trip-stat"
                    name="results"
                    onChange={handleSearch}
                    clearIcon={<ClearIcon />}
                    id="combo-box-demo"
                    options={statusoptions}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Status:All"
                        className="custom-notched-outline"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Container>
            {noresultsFound ? (
              <Container>
                <div className="manage-members-search-bar-no-results-found-main-div">
                  {" "}
                  <img
                    src="../../Assets/Images/Search illustration.jpg"
                    className="manage-members-search-illustration-img"
                  ></img>
                  <div className="manage-member-no-results-found">
                    Ups!... no results found
                  </div>
                  <div>
                    No content matched your keyword. Please try searching for
                    something else
                  </div>
                </div>
              </Container>
            ) : (
              <Container>
                <table className="manage-members-table">
                  <tr className="manage-members-heading-tr-main">
                    <th>UserId</th>
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>LastLogin</th>
                    <th>UserType</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>

                  {filteredData.map(item => (
                    <tbody key={item.id}>
                      <tr className="manage-members-heading-tr">
                        <td>
                          <p>{item.id}</p>
                        </td>
                        <td className="manage-members-img-td">
                          <p>{item.full_name ? item.full_name : "-"}</p>
                        </td>
                        <td>
                          <p>{item.email}</p>
                        </td>
                        <td>{item.last_login ? item.last_login : "-"}</td>
                        <td>
                          {item.user_type === "2" ? " Super Admin" : " Admin"}
                        </td>
                        <td>
                          <Switch
                            // checked={item.on_holding || item.is_active}
                            checked={item.is_active}
                            onChange={e => {
                              holdClickOpen(item.id, item.is_active);
                              setSwitchData(item);
                            }}
                          />
                          <Dialog
                            open={holdOpen}
                            onClose={handledeleteClose}
                            className=" managemembers-delete-dialog"
                            // aria-labelledby="alert-dialog-title"
                            // aria-describedby="alert-dialog-description"
                            PaperProps={{
                              style: {
                                minHeight: "40vh",
                                minWidth: "35vw",
                                boxShadow: "none",
                                border: "2px solid rgb(128 213 184 / 100%)",
                                strokeWidth: "1px",
                                stroke: "rgb(128 213 184 )",
                                borderRadius: 20,
                              },
                            }}
                            sx={{
                              "& .MuiBackdrop-root": {
                                backgroundColor: "transparent",
                            
                              },
                              "& .css-hz1bth-MuiDialog-container": {
                                height: 0,
                                padding: 0,
                              },
                            }}
                          >
                            <div>
                              <DialogTitle id="alert-dialog-title"></DialogTitle>

                              <DialogContent className="ads-management-delete-dalog">
                                {/* <h2 className="ads-management-areUSure">
                                  {" "}
                                  Are you sure, you want to inactive the user?
                                </h2> */}

                                {/* {item.on_hold ? (
                                  <h2 className="ads-management-areUSure">
                                    {" "}
                                    Are you sure, you want to active the user?
                                  </h2>
                                ) : (
                                  <h2 className="ads-management-areUSure">
                                    {" "}
                                    Are you sure, you want to inactive the user?
                                  </h2>
                                )}*/}
                                {holdOpen && (
                                  <h2 className="ads-management-areUSure">
                                    Are you sure, you want to{" "}
                                    {!holdValue ? "active" : "inactive"} the
                                    user?
                                  </h2>
                                )}
                              </DialogContent>
                              <DialogActions className="ads-management-buttonsContainer">
                                <Button
                                  id="btn"
                                  className="ads-management-yesButton"
                                  onClick={() => {
                                    console.log(switchdata, "item");
                                    Switchhandler(switchdata.id);
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  className="ads-management-noButton"
                                  onClick={() => setHoldOpen(false)}
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                        </td>
                        <td className="manage-members-main-td">
                          <Icon
                            icon="ic:outline-remove-red-eye"
                            className="manage-members-eye-icon"
                            onClick={() => popup(item)}
                          />

                          <Icon
                            onClick={() => {
                              handleClickOpen(item.id);
                            }}
                            icon="ic:baseline-delete"
                            className="manage-members-deleteicon"
                          />
                          <Dialog
                            open={open}
                            onClose={handledeleteClose}
                            className=" managemembers-delete-dialog"
                            // aria-labelledby="alert-dialog-title"
                            // aria-describedby="alert-dialog-description"
                            
                            PaperProps={{
                              style: {
                                minHeight: "40vh",
                                minWidth: "35vw",
                                boxShadow: "none",
                                border: "2px solid rgb(128 213 184 / 100%)",
                                strokeWidth: "1px",
                                stroke: "rgb(128 213 184 )",
                                borderRadius: 20,
                              },
                            }}
                            sx={{
                              "& .MuiBackdrop-root": {
                                backgroundColor: "transparent",
        
                              },
                              "& .css-hz1bth-MuiDialog-container": {
                                height: 0,
                                padding: 0,
                              },
                            }}
                          >
                            <div>
                              <DialogTitle id="alert-dialog-title"></DialogTitle>
                              <DialogContent className="upcoming-upcoming-delete-dalog">
                                <h2 className="upcoming-areUSure">
                                  {" "}
                                  Are you sure, you want to delete the account?
                                </h2>
                              </DialogContent>
                              <DialogActions className="upcoming-buttonsContainer">
                                <Button
                                  id="btn"
                                  className="upcoming-yesButton"
                                  onClick={() => {
                                    deleteCard();
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  className="upcoming-noButton"
                                  onClick={handleClose}
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </Container>
            )}

            <div>
              <div>
                {" "}
                {modalOpenAdtrpopInfo && (
                  <ManagePopup
                    createAdtrTripid={createAdtrTripid}
                    setOpenModalpopinfo={showModalAdtrpopInfo}
                    setTrip={ManageMembers}
                  />
                )}
              </div>

              {!noresultsFound && !modalOpenAdtrpopInfo && data && (
                <>
                  {(searchValue?.full_name !== undefined || data.count > 5) && (
                    <Pagination
                      className="manage-members-pagination"
                      count={Math.ceil(data.count / 5)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  )}
                </>
              )}

              {/* <Pagination
                className="manage-members-pagination"
                // count={Math.round(data.count / 5)}
                count={Math.ceil(data ? data.count / 5 : null)}
                page={currentPage}
                onChange={handlePageChange}
                  />*/}
            </div>
          </Card>
        </Container>
      </div>
    </>
  );
};
export default ManageMembers;
