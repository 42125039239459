import React, { useEffect } from "react";
import "./App.css";
// import LogInSide from './Auth/Login/Login';
import SignUp from "./Auth/SignUp/SignUp";
import ForgetPassword from "./Auth/ForgetPassword/forgetPassword";
import ResetPassword from "./Auth/ResetPassword/resetPassword";
import HomePage from "./Pages/HomePage/HomePage";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import {
  BrowserRouter,
  Route,
  Router,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./Auth/Login/Login";
import ProfilePage from "./Dashboard/ProfilePage/ProfilePage";
// import DashboardPage from './Dashboard/ProfilePage/Dashboard';
import Notification from "./Dashboard/Notification/Notification";
import Dashboard from "./Dashboard/Dashboard/Dashboard";
import Message from "./Dashboard/Message/Message";
import ProfileEdit from "./Dashboard/ProfilePage/ProfileEdit";
import ConncetedList from "./Dashboard/Invitation/InvitationConnectedList";
import InvitationConnectedList from "./Dashboard/Invitation/InvitationConnectedList";
import InvitationSentList from "./Dashboard/Invitation/InvitationSentList";
import InvitationRecievedList from "./Dashboard/Invitation/InvitationRecievedList";
import Settings from "./Dashboard/Settings/Settings";
import UpComingTrip from "./Dashboard/MyTrips/UpComingTrip";
import PastTrip from "./Dashboard/MyTrips/PastTrip";
import ConnectPeople from "./Pages/ConnectPeople/ConnectPeople";
// import CreateTrip from "./Pages/CreateTrip/CreateTrip";
import CreateTripNew from "./Pages/CreateTripNew/CreateTripNew";
import CreateTripEdit from "./Pages/CreateTripNew/CreateTripEdit";
import { useDispatch, useSelector } from "react-redux";
import StudentProfile from "./StudentProfile/StudentProfile";
import AboutUs from "./Pages/AboutUs/AboutUs";
import FAQ from "./FAQandPrivacyPolicy/FAQ";
import PrivacyPolicy from "./FAQandPrivacyPolicy/PrivacyPolicy";
import DesiCommunity from "./DesiCommunity/DesiCommunity";
import ContactUs from "./Pages/ContactUS/ContactUs";
import Ads from "./Pages/AdsPage/Ads";
import Location from "./Pages/Cityregcountry.js";
import Emailverification from "./Auth/EmailVerification/EmailVerification";
import TripCount from "./UpcomingTrip_count/TripCount";
import TermsAndConditions from "./Pages/TermsAndConditions";
import HowItWorks from "./Pages/HowItWorks/HowItWorks";
import axios from "axios";
import TravelLinkPage from "./Pages/TravelLinkPage";

import TripApproval from "./AdminDashboard/TripApproval";
import AdminCompanion from "./AdminDashboard/AdminCompanionSelfie";
import AdsManagement from "./AdminDashboard/AdsManagement";
import AdminLogin from "./AdminDashboard/AdminAuth/AdminLogin.js";
import AdsManagementCreate from "./AdminDashboard/AdsManagementCreate";
import AddMember from "./AdminDashboard/Member/AddMember"
import Admindashboard from "./AdminDashboard/Admindashboard";
import MyProfile from "./AdminDashboard/Settings/MyProfile";
import ChangePassword from "./AdminDashboard/Settings/ChangePassword";
import ManageMembers from "./AdminDashboard/Member/ManageMembers";
import RegisteredUser from "./AdminDashboard/Reports/RegisteredUser";
import Trips from "./AdminDashboard/Reports/Trips";
import UserandTripDetails from "./AdminDashboard/UserTripDetails";
import Usertrippopup from "./AdminDashboard/Usertrippopup";
import ForgetPasswordAdmin from "./Auth/ForgetPassword/forgetPasswordAdmin.js";
import ResetPasswordAdmin from "./Auth/ResetPassword/resetPasswordAdmin.js";

function App() {
  const { auth } = useSelector((state) => state);
  const { isadminLoggedIn,admin } = useSelector((state) => state.admin_auth);


  const theLocation = useLocation();
  const currentLocation = theLocation.pathname.split("/")[1];

  useEffect(() => {
    localStorage.removeItem("can");

    if (auth.user) TripCount(auth.user.user_id);
  }, [theLocation]);
  useEffect(() => {
    localStorage.removeItem("can");

    if (auth.user) TripCount(auth.user.user_id);
  }, []);
  useEffect(() => {
    localStorage.removeItem("can");

    if (auth.user) TripCount(auth.user.user_id);
  }, [auth.user ? auth.user : ""]);
  useEffect(() => {
    // let title = window.location.pathname.split("/")[1];
    // document.title = "Desi Travel Companion-"+title?title:"";
    document.title = currentLocation
      ? `Desi Travel Companion - 
        ${currentLocation[0].toUpperCase()}${currentLocation.slice(1)}`
      : "Desi Travel Companion Home - Travel Companion to USA / India and beyond";
  }, [currentLocation]);

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <Routes>
        {/* <Route path="/redirect" element={<RedirectUrl url="https://google.com" />} /> */}
        <Route
          path="/Travel-companion-to-india"
          element={<TravelLinkPage></TravelLinkPage>}
        ></Route>
        <Route path="/user-location" element={<Location></Location>}></Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/signup" element={<SignUp></SignUp>}></Route>
        <Route
          path="/forgot-password"
          element={<ForgetPassword></ForgetPassword>}
        ></Route>
        <Route
          path="/forgot-password-admin"
          element={<ForgetPasswordAdmin></ForgetPasswordAdmin>}
        ></Route>
        <Route
          path="/reset-password/:id/:token"
          element={<ResetPassword></ResetPassword>}
        ></Route>
                <Route
          path="/admin-reset-password/:id/:token"
          element={<ResetPasswordAdmin></ResetPasswordAdmin>}
        ></Route>
        <Route
          path="/email-verification/:id/:token"
          element={<Emailverification />}
        ></Route>
        {/* <Route path='/profile' element={auth.user?<ProfilePage></ProfilePage>:<Login></Login>}></Route>
          <Route path='/HomePage' element={<HomePage></HomePage>}></Route>
           <Route path='/dashboard' element={auth.user?<Dashboard></Dashboard>:<Login></Login>}></Route>
          <Route path='/notification' element={auth.user?<Notification></Notification>:<Login></Login>}></Route>
          <Route path='/message' element={auth.user?<Message></Message>:<Login></Login>}></Route>
          <Route path='/profileEdit' element={auth.user?<ProfileEdit></ProfileEdit>:<Login></Login>}></Route>
          <Route path='/connectedlist' element={auth.user?<InvitationConnectedList></InvitationConnectedList>:<Login></Login>}></Route>
          <Route path='/sentlist' element={auth.user?<InvitationSentList></InvitationSentList>:<Login></Login>}></Route>
          <Route path='/recievedlist' element={auth.user?<InvitationRecievedList></InvitationRecievedList>:<Login></Login>}></Route>
          <Route path='/settings' element={auth.user?<Settings></Settings>:<Login></Login>}></Route>
          <Route path='/upcomingtrip' element={auth.user?<UpComingTrip></UpComingTrip>:<Login></Login>}></Route>
          <Route path='/pasttrip' element={auth.user?<PastTrip></PastTrip>:<Login></Login>}></Route> */}
        {/* <Route path='/createtrip' element={auth.user?<CreateTrip></CreateTrip>:<Login></Login>}></Route>
          <Route path='/connectpeople' element={auth.user?<ConnectPeople></ConnectPeople>:<Login></Login>}></Route> */}
        {/* <Route path="/profile" element={<ProfilePage></ProfilePage>}></Route> */}
        <Route
          path="/profile"
          element={auth.user ? <ProfilePage></ProfilePage> : <Login></Login>}
        ></Route>
        <Route path="/" element={<HomePage></HomePage>}></Route>
        {/* <Route path="/dashboard" element={<Dashboard></Dashboard>}></Route> */}
        <Route
          path="/dashboard"
          element={auth.user ? <Dashboard></Dashboard> : <Login></Login>}
        ></Route>
        {/* <Route
            path="/notification"
            element={<Notification></Notification>}
          ></Route> */}
        <Route
          path="/notification"
          element={auth.user ? <Notification></Notification> : <Login></Login>}
        ></Route>
        <Route path="/message" element={<Message></Message>}></Route>
        {/* <Route
            path="/edit-profile"
            element={<ProfileEdit></ProfileEdit>}
          ></Route> */}
        <Route
          path="/edit-profile"
          element={auth.user ? <ProfileEdit></ProfileEdit> : <Login></Login>}
        ></Route>
        <Route
          path="/connected-list"
          element={
            auth.user ? (
              <InvitationConnectedList></InvitationConnectedList>
            ) : (
              <Login></Login>
            )
          }
        ></Route>
        <Route
          path="/sent-list"
          element={
            auth.user ? (
              <InvitationSentList></InvitationSentList>
            ) : (
              <Login></Login>
            )
          }
        ></Route>
        <Route
          path="/recieved-list"
          element={
            auth.user ? (
              <InvitationRecievedList></InvitationRecievedList>
            ) : (
              <Login></Login>
            )
          }
        ></Route>
        {/* <Route path="/settings" element={<Settings></Settings>}></Route> */}
        <Route
          path="/settings"
          element={auth.user ? <Settings></Settings> : <Login></Login>}
        ></Route>
        <Route
          path="/upcoming-trips"
          element={auth.user ? <UpComingTrip></UpComingTrip> : <Login></Login>}
        ></Route>
        <Route
          path="/create-a-trip-edit/:id"
          element={
            auth.user ? <CreateTripEdit></CreateTripEdit> : <Login></Login>
          }
        ></Route>
        <Route
          path="/past-trips"
          element={auth.user ? <PastTrip></PastTrip> : <Login></Login>}
        ></Route>
        {/* <Route path="/createtrip" element={<CreateTrip></CreateTrip>}></Route> */}

        <Route
          path="/create-a-trip"
          element={<CreateTripNew></CreateTripNew>}
        ></Route>
        <Route
          path="/companion-search"
          element={<ConnectPeople></ConnectPeople>}
        ></Route>
        <Route path="/about-us" element={<AboutUs></AboutUs>}></Route>
        <Route
          path="/student-community"
          element={<StudentProfile></StudentProfile>}
        ></Route>
        <Route path="/faq" element={<FAQ></FAQ>}></Route>
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy></PrivacyPolicy>}
        ></Route>
        <Route
          path="/desi-community"
          element={<DesiCommunity></DesiCommunity>}
        ></Route>
        <Route path="/contactus" element={<ContactUs></ContactUs>}></Route>
        <Route path="/ads" element={<Ads />}></Route>
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        ></Route>
        <Route path="/how-it-works" element={<HowItWorks />}></Route>
        <Route
          path="/admin/login"
          element={<AdminLogin />}
        ></Route>
            <Route
          path="/admin/Users-and-trips"
          element={<UserandTripDetails />}
        ></Route>
        <Route
          path="/admin/Users-and-trips/:id"
          element={<Usertrippopup />}
        ></Route>
        <Route
          path="/admin/trip-image-approval"
          element={<TripApproval />}
        ></Route>
        <Route
          path="/admin/companion-selfie-approval"
          element={<AdminCompanion />}
        ></Route>
    <Route
          path="/admin/profile"
          element={<MyProfile />}
        ></Route>
           <Route
          path="/admin/change-password"
          element={<ChangePassword />}
        ></Route>

        <Route
          path="/admin/admin-ads-management"
          element={admin==null?<AdminLogin />:admin.user_type=="3"?<TripApproval />:<AdsManagement />}
        ></Route>
        <Route
          path="/admin/admin-ads-management-create/:id"
          element={admin==null?<AdminLogin />:admin.user_type=="3"?<TripApproval />:<AdsManagementCreate />}
        ></Route>
                <Route
          path="/admin/add-member"
          element={admin==null?<AdminLogin />:admin.user_type=="3"?<TripApproval />:<AddMember />}
        ></Route>
   <Route
          path="/admin/manage-Members"
          element={admin==null?<AdminLogin />:admin.user_type=="3"?<TripApproval />:<ManageMembers />}
        ></Route>
          <Route
            path="/admin/dashboard"
            element={admin==null?<AdminLogin />:admin.user_type=="3"?<TripApproval />: <Admindashboard></Admindashboard> }
          ></Route>
   <Route
            path="/admin/registered-users"
            element={admin==null?<AdminLogin />:admin.user_type=="3"?<TripApproval />: <RegisteredUser></RegisteredUser> }
          ></Route>
           <Route
            path="/admin/trips"
            element={admin==null?<AdminLogin />:admin.user_type=="3"?<TripApproval />: <Trips></Trips> }
          ></Route>

      </Routes>
      {(() => {
        if (
          theLocation.pathname !== "/login" &&
          theLocation.pathname !== "/signup" &&
          theLocation.pathname !== "/forgot-password" &&
          theLocation.pathname !== "/reset-password/:id/:token" &&
          theLocation.pathname !== "/admin/trip-image-approval" &&
          theLocation.pathname !== "/admin/companion-selfie-approval" &&
          theLocation.pathname !== "/admin/admin-ads-management" &&
          theLocation.pathname !== "/admin/admin-ads-management-create/" &&
          theLocation.pathname !== "/admin/admin-ads-management-create/new" &&
          theLocation.pathname !== "/admin/add-member"&&
          theLocation.pathname !== "/admin/login" &&
          theLocation.pathname !== "/admin/dashboard"&&
          theLocation.pathname !== "/admin/manage-Members"&&
          theLocation.pathname !== "/admin/profile"&&
          theLocation.pathname !== "/admin/change-password" &&
          theLocation.pathname !== "/admin/registered-user" &&
          theLocation.pathname !== "/admin/trips" &&
          theLocation.pathname !== "/admin/Users-and-trips" &&
          theLocation.pathname !== "/admin/Users-and-trips/:id" 

        ) {
          return <Footer></Footer>;
        } else {
          return null;
        }
      })()}
      {/* </BrowserRouter> */}

      {/* <HomePage></HomePage> */}
    </div>
  );
}
export default App;
