import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Formik, Field, Form} from "formik";
// import "../../CSS/travellerDetails.css";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
// import "../../CSS/createTripCSS/travellerDetails.css";
import avatar from "./Assets/Images/Avatar.webp";
import StepCircle from "./Assets/Stepper/StepCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Icon} from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import defaultImage from "./Assets/Images/Favicon 20 WB _ DV.webp";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormControlLabel,
  FormLabel,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import {pink} from "@mui/material/colors";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {Controller, useForm} from "react-hook-form";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import "./CreateTripNew.css";
import isMobile from "./Assets/Ismobile";
import {EditTrip, getTripdata} from "./CreateTripApiCall";

function TravellerDetails(props) {
  const {isLoggedIn, user} = useSelector(state => state.auth);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log("user_id",user.user_id)

  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);

  const getingProfileData = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(`${domain}/user/profile/${isLoggedIn ? user.user_id : null}/`)
      .then(response => {
        console.log(response, "profile response");

        if (response.data) {
          console.log(response.data, "profile response data");
          setProfileData(response.data[0]);
        }
      })
      .catch(error => {
        // Error
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
  useEffect(() => {
    if (isMobile()) {
      window.scrollTo(0, 900);
      console.log("Mobile....");
    } else {
      window.scrollTo(0, 1300);
      console.log("Desktop....");
    }
    getingProfileData();
  }, []);

  const {
    control,
    values,
    reset,
    setValue,
    errors,
    register,
    customError,
    setCustomError,
    page,
  } = props;

  const handleChange = event => {
    const {name, value} = event.target;
    if (name === "user_type" && value === "1") {
      setValue("age_group", profileData.age_group, {shouldValidate: true});
      setValue("name", profileData.full_name, {shouldValidate: true});
      setValue("gender", profileData.gender, {shouldValidate: true});
      // setValue("photo",profileData.photo)
      setValue("booking_status", "");
      setValue("date_range_from", null);
      setValue("date_range_to", null);
      setValue("departing_on", "");
      setValue("airAndTrans", [
        {
          transit: "",
          airline: "",
        },
      ]);
    }
    if (name === "user_type" && value === "2") {
      if (values.user_type) {
        setValue("age_group", "");
        setValue("name", "");
        setValue("photo", "");
        setValue("gender", "");
      }
      setValue("booking_status", "");
      setValue("date_range_from", "");
      setValue("date_range_to", "");
      setValue("departing_on", null);
    }
    setValue(name, value, {shouldValidate: true});
    console.log(name, value, "event");
  };
  // console.log(values,"values")

  const deletePost = () => {
    setValue("photo", "");
    setLoading(true);
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .delete(`${domain}/create-trip/traveler-photo/delete/${values.id}/`)
      .then(response => {
        console.log(response.data, "deletepicincreateTrip");
        if (response.data) {
          // setOpen(false);
          setLoading(false);
          getTripdata(values.id).then(res => {
            reset(res);
            console.log(res, "resinEdit");
          });
          // window.location.reload();
          // setDeletePic("2");
        }
      });
  };

  const handleUpload = () => {
    const fileInput = document.getElementById("upload_pro");
    fileInput.click();
    fileInput.value = "";
  };

  const ageGroup = [
    {
      id: "20-25",
      name: "20-25",
    },
    {
      id: "26-30",
      name: "26-30",
    },
    {
      id: "31-35",
      name: "31-35",
    },
    {
      id: "36-40",
      name: "36-40",
    },
    {
      id: "41-45",
      name: "41-45",
    },
    {
      id: "46-50",
      name: "46-50",
    },
    {
      id: "51-60",
      name: "51-60",
    },
    {
      id: "61-70",
      name: "61-70",
    },
    {
      id: "71-80",
      name: "71-80",
    },
  ];

  const genderGroup = [
    {
      id: "male",
      name: "Male",
    },
    {
      id: "female",
      name: "Female",
    },
  ];

  const uploadpicture = () => {
    setLoading(true);
    values["photo"] = values.photo_temp;
    EditTrip(values).then(response => {
      console.log(response, "responseInUpload");
      delete values.photo_temp;
      setLoading(false);
      getTripdata(values.id).then(res => {
        reset(res);
        console.log(res, "resinEdit");
        //setOpen(false)
      });
    });
  };

  return (
    <Container className="responsive">
      <Grid>
        <Grid className="card_container mt-2">
          <Card
            onClick={() => {
              setValue("category", "2", {shouldValidate: true});
              if (values.category) {
                setValue("age_group", "");
                setValue("name", "");
                setValue("photo", "");
                setValue("gender", "");
                setValue("user_type", "");
              }
              setValue("booking_status", "");
              setValue("airline", "Yet to Book");
              setValue("tip_expected", "Null");
              // setValue("gender", "");
              //
            }}
            className={
              values.category === "2"
                ? "activeTravellerNeed cardHeight"
                : "travellerdNeed cardHeight"
            }
          >
            <Typography className="travellerdeatils-needtravel-text">
              Need a Travel Companion
            </Typography>
          </Card>

          <Card
            onClick={() => {
              setValue("category", "1", {shouldValidate: true});
              setValue("age_group", profileData.age_group, {
                shouldValidate: true,
              });
              setValue("name", profileData.full_name, {shouldValidate: true});
              setValue("gender", profileData.gender, {shouldValidate: true});
              // setValue("photo",profileData.profile_picture)
              setValue("booking_status", "1");
              setValue("tip_expected", "");
              setValue("airline", "");
              setValue("user_type", "1", {shouldValidate: true});
              setValue("airAndTrans", [
                {
                  transit: "",
                  airline: "",
                },
              ]);
            }}
            className={
              values.category === "1" ? "activeTravellerNeed" : "travellerdNeed"
            }
          >
            <Typography className="travellerdeatils-needtravel-text">
              I can be your Travel Companion
            </Typography>
          </Card>
        </Grid>
        <div className="error_w_100">
          {errors.category && errors.category.message}
        </div>
      </Grid>

      <Grid>
        {(values.category === "2" || values.category === "") && (
          <Box className="center mt-3">
            <Controller
              name="user_type"
              control={control}
              render={({field: {onChange, value, name}}) => (
                <Grid className="radio_container_2">
                  <Grid className="Radio">
                    <Typography variant="h6">On Behalf of Traveler</Typography>
                    <Radio
                      checked={values.user_type === "2"}
                      onChange={handleChange}
                      value="2"
                      name={name}
                      //  inputProps={{ 'aria-label': 'A' }}
                      sx={{
                        color: "#ff8d8d",
                        "&.Mui-checked": {
                          color: "#ff8d8d",
                        },
                      }}
                    />
                  </Grid>

                  <Grid className="Radio">
                    <Typography variant="h6">Self</Typography>
                    <Radio
                      checked={values.user_type === "1"}
                      onChange={handleChange}
                      value="1"
                      name={name}
                      sx={{
                        color: "#ff8d8d",
                        "&.Mui-checked": {
                          color: "#ff8d8d",
                        },
                      }}

                      //  inputProps={{ 'aria-label': 'A' }}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Box>
        )}
        <div className="error_w_100">
          {errors.user_type && errors.user_type.message}
        </div>
      </Grid>

      <Grid>
        <Grid className="Image_upload_container mt-4">
          <Grid
            style={{
              // backgroundColor:"red",
              height: "6rem",
            }}
          >
            <Typography
              variant="h6"
              style={{
                textAlign: "initial",
                // backgroundColor:"yellow",
                position: "relative",
                top: 0,
              }}
            >
              Traveler Picture
            </Typography>
          </Grid>

          <Grid>
            <Grid>
              <Controller
                name="photo"
                control={control}
                render={({field: {onChange, value, name}}) => (
                  <>
                    <input
                      accept="image/*"
                      hidden={true}
                      id="upload_pro"
                      type="file"
                      onChange={e => {
                        console.log(e, "pricture");
                        console.log(e.target.files[0], "pricture");
                        setValue("photo", e.target.files[0]);
                        // e.target.files=null
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid style={{width: "100%", position: "relative"}}>
              {props.page !== "createTrip_edit" ? (
                <div
                  className={
                    values.photo ? "addPhoto_with" : "addPhoto_without"
                  }
                >
                  <AddCircleIcon
                    style={{color: "#1ec28b"}}
                    onClick={handleUpload}
                  />
                  {values.photo && !page && (
                    <RemoveCircleIcon
                      style={{color: "#1ec28b"}}
                      onClick={() => {
                        setValue("photo", "");
                      }}
                    />
                  )}
                </div>
              ) : (
                <div
                  className={
                    values.photo
                      ? "addPhoto_with_camera"
                      : "addPhoto_without_camera"
                  }
                >
                  <CameraAltIcon
                    style={{color: "#1ec28b"}}
                    onClick={handleUpload}
                  />
                </div>
              )}
              {!values.photo ? (
                <Grid className="Avatar_con">
                  <img style={{height: "100%", width: "100%"}} src={avatar} />
                </Grid>
              ) : (
                <StepCircle imageFile={values.photo} />
              )}
            </Grid>
            {props.page === "createTrip_edit" && (
              <div>
                <div
                  // style={{backgroundColor:"red"}}
                  className="edit-btn"
                >
                  <Button onClick={handleOpen}>
                    <AddCircleIcon className="plusIcon"></AddCircleIcon>
                    Edit Photo
                  </Button>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid className="">
        {(values.category === "2" || values.category === "") &&
        (values.user_type === "2" || values.user_type === "") ? (
          <Grid className="field1 inputField">
            <Controller
              name="name"
              control={control}
              render={({field: {onChange, value, name}}) => (
                <FormControl fullWidth hidden={true}>
                  <InputLabel
                    htmlFor="standard-adornment-amount"
                    shrink={value ? true : false}
                  >
                    Traveler Name
                    <span style={{color: "#ff8d8d", fontSize: 19}}>*</span>
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={value}
                    variant="standard"
                    color="success"
                    className="aaaaa"
                    InputLabelProps={{
                      shrink: value ? true : false,
                      focused: value ? true : false,
                    }}
                    //  color="success"
                    onChange={e => {
                      const {name, value} = e.target;
                      if (/^[a-zA-Z ]*$/.test(value) && value.length <= 40) {
                        if (customError.name) delete customError.name;
                        setValue("name", value, {shouldValidate: true});
                      } else {
                        if (/^[a-zA-Z ]*$/.test(value)) {
                          setCustomError({
                            ...customError,
                            ["name"]:
                              "Name should not be exceeding 40 chracters",
                          });
                        } else {
                          setCustomError({
                            ...customError,
                            ["name"]:
                              "Name should not contains special chracters and numbers",
                          });
                        }
                      }
                    }}
                    startAdornment={""}
                  />
                </FormControl>
              )}
            />
            {/* <div className="error_w_100">
              {errors.name && errors.name.message}
            </div> */}
            <div className="error_w_100">
              {customError.name
                ? customError.name
                : errors.name && errors.name.message}
            </div>
          </Grid>
        ) : (
          <Grid className="field1 inputField">
            <Controller
              name="name"
              control={control}
              render={({field: {onChange, value, name}}) => (
                <FormControl fullWidth hidden={true}>
                  <InputLabel
                    htmlFor="standard-adornment-amount"
                    shrink={value ? true : false}
                  >
                    Traveler Name
                    <span style={{color: "#ff8d8d", fontSize: 19}}>*</span>
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={value}
                    variant="standard"
                    color="success"
                    className="aaaaa"
                    disabled={true}
                    InputLabelProps={{
                      shrink: value ? true : false,
                      focused: value ? true : false,
                    }}
                    //  color="success"
                    onChange={e => {
                      const {name, value} = e.target;
                      if (/^[a-zA-Z ]*$/.test(value) && value.length <= 40) {
                        setValue("name", value, {shouldValidate: true});
                      } else {
                        if (!value.length <= 40) {
                          setCustomError({
                            ...customError,
                            ["name"]:
                              "Name should not be exceeding 40 chracters",
                          });
                        } else {
                          setCustomError({
                            ...customError,
                            ["name"]:
                              "Name should not contain special chracters",
                          });
                        }
                      }
                    }}
                    startAdornment={""}
                  />
                </FormControl>
              )}
            />
            <div className="error_w_100">
              {errors.name && errors.name.message}
            </div>

            <div className="error_w_100">
              {customError.name && customError.name}
            </div>
          </Grid>
        )}

        {/* ----------------------------- Age Field start ---------------------------------------- */}

        {(values.category === "2" || values.category === "") &&
        (values.user_type === "2" || values.user_type === "") ? (
          <Grid className="field1 inputField">
            <Controller
              name="age_group"
              control={control}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    className="travellerAgeNamelabel"
                    shrink={value ? true : false}
                  >
                    Age Group
                    <span style={{color: "#ff8d8d", fontSize: 19}}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    variant="standard"
                    id="demo-simple-select"
                    value={value}
                    label="Age"
                    className="aaaaa"
                    onChange={onChange}
                    InputLabelProps={{
                      shrink: value ? true : false,
                      focused: value ? true : false,
                    }}
                    sx={{
                      ".MuiSelect-icon": {
                        color: "#ff8d8d",
                        fontSize: "xx-large",
                      },
                      ".MuiSelect-outlined": {
                        color: "green",
                      },
                      "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":
                        {
                          paddingRight: "92% !important",
                        },
                    }}
                  >
                    {ageGroup.map((c, i) => (
                      <MenuItem key={`c-${i}`} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <div className="error_w_100">
              {errors.age_group && errors.age_group.message}
            </div>
          </Grid>
        ) : (
          <Grid className="field1 inputField">
            <Controller
              name="age_group"
              control={control}
              render={({field: {onChange, value, name}}) => (
                <FormControl fullWidth hidden={true}>
                  <InputLabel
                    htmlFor="standard-adornment-amount"
                    shrink={value ? true : false}
                  >
                    Age
                    <span style={{color: "#ff8d8d", fontSize: 19}}>*</span>
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={value}
                    variant="standard"
                    color="success"
                    className="aaaaa"
                    disabled={true}
                    onChange={e => {
                      const {name, value} = e.target;
                      if (/^[a-zA-Z ]*$/.test(value)) {
                        setValue("age_group", value, {shouldValidate: true});
                      }
                    }}
                    InputLabelProps={{
                      shrink: value ? true : false,
                      focused: value ? true : false,
                    }}
                    //  color="success"
                    // onChange={onChange}
                    startAdornment={""}
                  />
                </FormControl>
              )}
            />
          </Grid>
        )}
        {/* ----------------------------- Age Field start ---------------------------------------- */}

        {/* ----------------------------- Gender Field start ---------------------------------------- */}

        {(values.category === "2" || values.category === "") &&
        (values.user_type === "2" || values.user_type === "") ? (
          <Grid className="field1 inputField">
            <Controller
              render={({field: {onChange, value}}) => (
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    className=""
                    shrink={value ? true : false}
                  >
                    Gender
                    <span style={{color: "#ff8d8d", fontSize: 19}}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    variant="standard"
                    id="demo-simple-select"
                    value={value}
                    label="Gender"
                    className="aaaaa"
                    onChange={onChange}
                    InputLabelProps={{
                      shrink: value ? true : false,
                      focused: value ? true : false,
                    }}
                    sx={{
                      ".MuiSelect-icon": {
                        color: "#ff8d8d",
                        fontSize: "xx-large",
                      },
                      ".MuiSelect-outlined": {
                        color: "green",
                      },
                      "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":
                        {
                          paddingRight: "92% !important",
                        },
                    }}
                  >
                    {/* <MenuItem value={10}>Male</MenuItem>
                  <MenuItem value={20}>Female</MenuItem> */}
                    {/* <MenuItem value={30}>Other</MenuItem> */}
                    {genderGroup.map((c, i) => (
                      <MenuItem key={`c-${i}`} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              control={control}
              name="gender"
              defaultValue=""
            />
            <div className="error_w_100">
              {errors.gender && errors.gender.message}
            </div>
          </Grid>
        ) : (
          <Grid className="field1 inputField">
            <Controller
              name="gender"
              control={control}
              render={({field: {onChange, value, name}}) => (
                <FormControl fullWidth hidden={true}>
                  <InputLabel
                    htmlFor="standard-adornment-amount"
                    shrink={value ? true : false}
                  >
                    Gender
                    <span style={{color: "#ff8d8d", fontSize: 19}}>*</span>
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={value}
                    variant="standard"
                    color="success"
                    className="aaaaa"
                    disabled={true}
                    InputLabelProps={{
                      shrink: value ? true : false,
                      focused: value ? true : false,
                    }}
                    //  color="success"
                    onChange={onChange}
                    startAdornment={""}
                  />
                </FormControl>
              )}
            />
          </Grid>
        )}
        {/* ----------------------------- Gender Field start ---------------------------------------- */}

        {values.category === "1" && (
          <Grid className="field1 inputField">
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                className="travellerGenderNamelabel"
              >
                Tip I Expect
                <span style={{color: "#ff8d8d", fontSize: 19}}>*</span>
              </InputLabel>
              <Controller
                control={control}
                name="tip_expected"
                defaultValue=""
                render={({field: {onChange, value}}) => (
                  <Select
                    labelId="demo-simple-select-label"
                    variant="standard"
                    id="demo-simple-select"
                    value={value}
                    label="Gender"
                    onChange={onChange}
                    className="aaaaa"
                    sx={{
                      ".MuiSelect-icon": {
                        color: "#ff8d8d",
                        fontSize: "xx-large",
                      },
                      ".MuiSelect-outlined": {
                        color: "green",
                      },
                      "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":
                        {
                          paddingRight: "92% !important",
                        },
                    }}
                  >
                    <MenuItem value="50">50 USD equiv</MenuItem>
                    <MenuItem value="75">75 USD equiv</MenuItem>
                    <MenuItem value="100">100 USD equiv</MenuItem>
                    <MenuItem value="Just helping">Just helping</MenuItem>
                    <MenuItem value="Negotiable">Negotiable </MenuItem>
                  </Select>
                )}
              />
              <div className="error_w_100">
                {errors.tip_expected && errors.tip_expected.message}
              </div>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="profileedit-popupbackground">
          <Grid container spacing={2} className="profileedit-main-heading">
            <Grid
              item
              xl={10}
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="profileedit-heading"
            >
              <div>Traveler Image Update/Delete</div>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <span onClick={handleClose} className="profiledit-main-icon">
                <Icon
                  icon="iconoir:cancel"
                  className="profieedit-profile-icon"
                />
              </span>
            </Grid>
          </Grid>

          <div>
            {" "}
            <Grid container spacing={2}>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="profilepopup-editpopup-outer"
              >
                <div className="profilepopup-editpopup-inner">
                  <div className="profilepopupEditImg-con">
                    <img
                      className=""
                      variant="top"
                      //       src={values.photo
                      // ? (typeof values.photo==="string"?values.photo:URL.createObjectURL(values.photo))
                      // :(values.photo_temp?values.photo_temp: defaultImage)
                      //       }
                      src={
                        values.photo_temp
                          ? URL.createObjectURL(values.photo_temp)
                          : values.photo
                          ? values.photo
                          : defaultImage
                      }
                    />
                    {loading && (
                      <div className="transparent-loading">
                        <CircularProgress
                          color="secondary"
                          sx={{color: "#ff8d8d"}}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} className="">
            {values.photo_temp ? (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                xs={6}
                sm={6}
                className="profileedit-btnpart"
              >
                <Button
                  onClick={() => setValue("photo_temp", "")}
                  className="profiledit-removephoto"
                  // disabled={values.photo?false:true}
                >
                  <CloseIcon className="profile-popup-edit-changeicon"></CloseIcon>{" "}
                  Cancel
                </Button>
              </Grid>
            ) : (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                xs={6}
                sm={6}
                className="profileedit-btnpart"
              >
                <Button
                  onClick={() => deletePost()}
                  className="profiledit-removephoto"
                  activeOpacity={0.2}
                  style={{
                    opacity: values.photo == null ? 0.5 : 1.0,
                    disabled: values.photo == null ? true : false,
                  }}
                  // style={{visibility: formik.values.photo==null?"hidden":''}}
                  disabled={!values.photo ? true : false}
                >
                  <span>
                    <Icon
                      icon="material-symbols:delete-outline"
                      className="profile-popup-edit-changeicon"
                    />
                  </span>
                  &nbsp; Remove Photo
                </Button>
              </Grid>
            )}
            {values.photo_temp ? (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                xs={6}
                sm={6}
                className="profileedit-btnpart"
              >
                <label htmlFor="icon-button-photo">
                  <Button
                    onClick={() => uploadpicture()}
                    variant="text"
                    component="span"
                    className="profilepopupedit-addphoto"
                  >
                    <FileUploadIcon className="profile-popup-edit-changeicon"></FileUploadIcon>{" "}
                    Upload
                  </Button>
                </label>

                {/* </div>  */}
              </Grid>
            ) : (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                xs={6}
                sm={6}
                className="profileedit-btnpart"
                // style={{margin: formik.values.photo?"":'auto'}}
              >
                <input
                  style={{display: "none"}}
                  id="icon-button-photo"
                  type="file"
                  accept="image/*"
                  name="photo"
                  onChange={event => {
                    console.log(event.target.files[0], "data of file");
                    setValue("photo_temp", event.target.files[0]);
                    setValue("photo", values.photo);
                  }}
                />
                <label htmlFor="icon-button-photo">
                  <Button
                    variant="text"
                    component="span"
                    className="profilepopupedit-addphoto"
                  >
                    <span>
                      <Icon
                        icon="mdi:image-add"
                        className="profile-popup-edit-changeicon"
                      />
                    </span>
                    &nbsp; Change Photo
                  </Button>
                </label>

                {/* </div>  */}
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default TravellerDetails;